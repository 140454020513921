<template>
  <div class="price_index js_price_index">
    <Introduce :moduleId="7"/>
    <p class="title">多种解决方案，满足企业不同需求</p>
    <div class="price_box">
      <ul class="mob_title" :class="{four_title:list.length===4}">
        <li v-for="(item,index) in list" :key="item.pricingId" :class="{active:mobIndex===index}"
            @click="mobIndexClick(index)">{{ item.releaseName }}
        </li>
      </ul>
      <ul class="price_cntent" :class="{price_one:list.length===1}" v-if="list.length>0">
        <li v-for="(item,index) in list" :key="item.pricingId" v-show="mobIndex===index" class="price_every">
          <p class="con_title" :class="`con_title_bc${index+1}`">{{ item.releaseName }}</p>
          <div class="con_priarr" v-if="item.pricingList && item.pricingList.length===1">
            <div class="con_one_box">
              <p class="con_one_price"><label>￥</label>{{ item.pricingList[0].price }}</p>
              <p class="con_one_time">{{ item.pricingList[0].unit }}</p>
            </div>
          </div>
          <div class="con_priarr" v-else>
            <p class="con_more" v-for="(priceItem,priceIndex) in item.pricingList" :key="priceIndex">
              <span><label>￥</label>{{ priceItem.price }}</span>
              <span>{{ priceItem.unit }}</span>
            </p>
          </div>
          <p class="con_desc js_con_desc">{{ item.releaseProfile }}</p>
          <div class="con_list">
            <p class="con_include" v-if="item.containRelease">包含<span :class="`con_include_bc${index+1}`">{{ item.containRelease }}</span>功能，以及</p>
            <p class="con_list_one" v-for="(funItem,funIndex) in item.functionName" :key="funIndex">
              <i class="font_family icon-price_icon"></i>
              <span>{{ funItem }}</span>
            </p>
          </div>
        </li>
      </ul>
      <Nothing class="nothing" v-else />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import {getProductPricing} from "@/api/common/index.js";
import metaInfoMixins from "@/utils/mixin/metaInfoMixins";

export default {
  name: "index",
  mixins: [metaInfoMixins],
  data() {
    return {
      mobIndex: 0,
      list: []
    };
  },
  created() {
    if (this.$route.query.id) {
      this.mobIndex = parseInt(this.$route.query.id);
    }
    this.getProductPricingFun();
  },
  watch: {
    '$route'() {
      if (this.$route.query.id) {
        this.mobIndex = parseInt(this.$route.query.id);
      }
    }
  },
  mounted() {
    const el = $(".js_price_index");
    this.onResize(el);
  },
  methods: {
    getProductPricingFun() {
      getProductPricing().then((res) => {
        if (res.success) {
          this.list = res.data.contentList;
          this.$nextTick(() => {
            // 动态设置中间的高度
            const el = $(".js_price_index");
            this.setArrWidt(el);
          });
        }
      });
    },
    onResize(el) {
      window.onresize = () => {
        this.setArrWidt(el);
      };
    },
    setArrWidt(el) {
      let w = $(window).width();
      if (w > 720) {
        el.find(".con_priarr").css("height", "auto");
        let maxWidth = 0;
        el.find(".con_priarr").each((e, dom) => {
          let wid = $(dom).outerHeight();
          if (wid > maxWidth) {
            maxWidth = wid;
          }
        });
        el.find(".con_priarr").css("height", maxWidth + "px");
        let maxWidth2 = 0;
        el.find(".js_con_desc").each((e, dom) => {
          let wid = $(dom).outerHeight();
          if (wid > maxWidth2) {
            maxWidth2 = wid;
          }
        });
        el.find(".js_con_desc").css("height", maxWidth2 + "px");
      } else {
        el.find(".con_priarr").css("height", "100%");
        el.find(".js_con_desc").css("height", "auto");
      }
    },
    mobIndexClick(i) {
      this.mobIndex = i;
    }
  }
};
</script>

<style scoped lang="scss">
.price_index {
  .title {
    font-size: 32px;
    color: #333333;
    line-height: 45px;
    padding: 60px 0;
    text-align: center;
  }
  .price_box {
    padding: 0 60px;
    .mob_title {
      display: flex;
      li {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 66px;
        background: #FFFFFF;
        font-size: 28px;
        color: #333333;
        margin-left: 24px;
        &:first-child {
          margin-left: 0;
        }
      }
      .active {
        background: #0064EB;
        color: #FFFFFF;
      }
    }
    .four_title{
      flex-wrap: wrap;
      li{
        flex: none;
        width: 300px;
        margin-left: 24px;
        &:nth-child(2n+1){
          margin-left: 0;
        }
        &:nth-child(3){
          margin-top: 24px;
        }
        &:nth-child(4){
          margin-top: 24px;
        }
      }
    }
    .price_cntent {
      margin-top: 40px;
      padding-bottom: 60px;
      .price_every {
        width: 100%;
        background-color: #FFFFFF;
        .con_title {
          width: 100%;
          height: 122px;
          background: linear-gradient(108deg, #BDC9DC 0%, #8899B7 100%);
          font-size: 32px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
        }
        .con_title_bc1 {
          background: linear-gradient(108deg, #BDC9DC 0%, #8899B7 100%);
        }
        .con_title_bc2 {
          background: linear-gradient(90deg, #4E95F3 0%, #0056CB 100%);
        }
        .con_title_bc3 {
          background: linear-gradient(114deg, #405FB2 1%, #001F6E 100%);
        }
        .con_title_bc4 {
          background: linear-gradient(114deg, #605E69 0%, #252931 100%);
        }
        .con_priarr {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 40px 0;
          .con_one_box{
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .con_one_price {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 54px;
            color: #FE5B02;
            line-height: 75px;
            text-align: center;
            font-weight: bold;
            label{
              font-size: 40px;
            }
          }
          .con_one_time {
            font-size: 26px;
            color: #333333;
            line-height: 37px;
            margin-left: 32px;
            text-align: center;
          }
          .con_more {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            span {
              &:first-child {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 219px;
                text-align: right;
                font-size: 36px;
                color: #FE5B02;
                line-height: 50px;
                font-weight: bold;
                label{
                  font-size: 30px;
                }
              }
              &:last-child {
                text-align: left;
                width: 208px;
                font-size: 26px;
                color: #333333;
                line-height: 37px;
                margin-left: 32px;
              }
            }
          }
        }
        .con_desc {
          font-size: 24px;
          color: #666666;
          line-height: 33px;
          padding: 0 40px;
          text-align: center;
        }
        .con_list {
          padding: 40px 0 60px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          .con_include {
            font-size: 24px;
            color: #333333;
            line-height: 33px;
            display: flex;
            align-items: center;
            span {
              display: inline-block;
              padding: 2px 12px;
              border-radius: 6px;
              font-size: 26px;
              font-weight: 600;
              color: #FFFFFF;
              margin: 0 8px;
            }
            .con_include_bc1{
              background: linear-gradient(108deg, #BDC9DC 0%, #8899B7 100%);
            }
            .con_include_bc2 {
              background: linear-gradient(90deg, #4E95F3 0%, #0056CB 100%);
            }
            .con_include_bc3 {
              background: linear-gradient(114deg, #405FB2 1%, #001F6E 100%);
            }
            .con_include_bc4 {
              background: linear-gradient(114deg, #605E69 0%, #252931 100%);
            }
          }
          .con_list_one {
            margin-top: 22px;
            width: 330px;
            display: flex;
            align-items: center;
            &:first-child {
              margin-top: 0;
            }
            i {
              font-size: 24px;
              color: #0064EB;
            }
            span {
              margin-left: 15px;
              font-size: 24px;
              color: #333333;
              line-height: 33px;
            }
          }
        }
      }
    }
    .nothing{
      padding-bottom: 60px;
    }
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .price_index {
    .title {
      font-size: 32px;
      line-height: 45px;
      padding: 60px 0 40px 0;
      font-weight: bold;
    }
    .price_box {
      width:100%;
      margin: 0 auto;
      padding: 0;
      .mob_title {
        display: none;
      }
      .price_cntent {
        margin-top: 0;
        padding-left: 60px;
        padding-right: 60px;
        margin-bottom: 60px;
        padding-bottom: 0;
        overflow-x: auto;
        display: flex;
        justify-content: flex-start;
        .price_every {
          display: flex !important;
          flex-shrink:0;
          flex-direction: column;
          margin-left: 24px;
          width: 408px;
          background-color: #FFFFFF;
          &:first-child {
            margin-left: 0;
          }
          .con_title {
            width: 100%;
            height: 122px;
            font-size: 32px;
            flex-shrink: 0;
          }
          .con_priarr {
            .con_one_box{
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .con_one_price {
              font-size: 48px;
              color: #FE5B02;
              line-height: 67px;
              label{
                font-size: 40px;
              }
            }
            .con_one_time {
              font-size: 20px;
              color: #333333;
              line-height: 28px;
              text-align: center;
              margin-left: 16px;
            }
            .con_more {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 20px;
              &:first-child {
                margin-top: 0;
              }
              span {
                &:first-child {
                  width: 200px;
                  font-size: 30px;
                  color: #FE5B02;
                  line-height: 42px;
                  label{
                    font-size: 26px;
                  }
                }
                &:last-child {
                  text-align: left;
                  width: 177px;
                  font-size: 20px;
                  color: #333333;
                  line-height: 28px;
                  margin-left: 32px;
                }
              }
            }
          }
          .con_desc {
            font-size: 20px;
            line-height: 28px;
            padding: 0 24px;
          }
          .con_list {
            padding: 40px 0 60px 0;
            .con_include {
              font-size: 26px;
              color: #333333;
              line-height: 37px;
              font-weight: 400;
              display: flex;
              align-items: center;
              span {
                display: inline-block;
                padding: 2px 12px;
                background: linear-gradient(108deg, #BDC9DC 0%, #8899B7 100%);
                border-radius: 6px;
                font-size: 26px;
                font-weight: 600;
                color: #FFFFFF;
                margin: 0 8px;
              }
            }
            .con_list_one {
              margin-top: 24px;
              width: 280px;
              &:first-child {
                margin-top: 0;
              }
              i {
                font-size: 24px;
                color: #0064EB;
              }
              span {
                margin-left: 15px;
                font-size: 24px;
                line-height: 33px;
              }
            }
          }
        }
      }
      .price_one{
        justify-content: center;
      }
    }
  }
}
@media (min-width: 961px) {
  .price_index {
    .title {
      font-size: 32px;
      line-height: 45px;
      padding: 60px 0 40px 0;
      font-weight: bold;
    }
    .price_box {
      width: 1200px;
      margin: 0 auto;
      padding: 0;
      .mob_title {
        display: none;
      }
      .price_cntent {
        margin-top: 0;
        padding-bottom: 60px;
        display: flex;
        justify-content: center;
        .price_every {
          display: flex !important;
          flex-direction: column;
          margin-left: 24px;
          flex: 1;
          max-width: 50%;
          background-color: #FFFFFF;
          &:hover{
            box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
          }
          &:first-child {
            margin-left: 0;
          }
          .con_title {
            width: 100%;
            height: 100px;
            font-size: 28px;
            flex-shrink: 0;
          }
          .con_priarr {
            .con_one_box{
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .con_one_price {
              font-size: 44px;
              color: #FE5B02;
              line-height: 67px;
              label{
                font-size: 30px;
              }
            }
            .con_one_time {
              font-size: 22px;
              color: #333333;
              line-height: 28px;
              text-align: center;
              margin-left: 16px;
            }
            .con_more {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 16px;
              &:first-child {
                margin-top: 0;
              }
              span {
                &:first-child {
                  width: 168px;
                  font-size: 26px;
                  color: #FE5B02;
                  line-height: 37px;
                  label{
                    font-size: 22px;
                  }
                }
                &:last-child {
                  text-align: left;
                  width: 100px;
                  font-size: 20px;
                  color: #333333;
                  line-height: 25px;
                  margin-left: 16px;
                }
              }
            }
          }
          .con_desc {
            font-size: 18px;
            line-height: 25px;
            padding: 0 24px;
          }
          .con_list {
            padding: 40px 0 60px 0;
            .con_include {
              font-size: 18px;
              color: #333333;
              line-height: 25px;
              font-weight: 400;
              display: flex;
              align-items: center;
              span {
                display: inline-block;
                padding: 2px 12px;
                background: linear-gradient(108deg, #BDC9DC 0%, #8899B7 100%);
                border-radius: 3px;
                font-size: 18px;
                font-weight: 600;
                color: #FFFFFF;
                margin: 0 6px;
              }
            }
            .con_list_one {
              margin-top: 13px;
              width: 214px;
              &:first-child {
                margin-top: 0;
              }
              i {
                font-size: 18px;
                color: #0064EB;
              }
              span {
                margin-left: 9px;
                font-size: 18px;
                line-height: 25px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
